<template>
  <v-card>
    <v-card-title
      class="headline msaBlue white--text text-h6 pa-2 font-weight-bold"
    >
      General Information
    </v-card-title>
    <v-card-text>
      <v-container class="mt-4">
        <v-row>
          <v-col>
            <v-text-field
              v-model="edited.name"
              label="Company Name"
              outlined
              dense
              counter
              :disabled="
                disableAll || $store.getters.user.companyId == edited.id
              "
              maxlength="200"
              :rules="rules.name"
              data-testid="company-name"
            >
            </v-text-field>
            <div class="text-caption ml-2">
              If you require a company name change, please contact 1Life
              support.
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-textarea
              label="Description"
              v-model="edited.description"
              outlined
              dense
              :disabled="disableAll"
              counter
              maxlength="2000"
              data-testid="company-description"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="edited.addressLine1"
              label="Address"
              outlined
              dense
              counter
              maxlength="75"
              :disabled="disableAll"
              placeholder="Address Line 1"
              data-testid="company-address"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="edited.addressLine2"
              outlined
              dense
              counter
              maxlength="75"
              :disabled="disableAll"
              placeholder="Address Line 2"
              data-testid="company-address2"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="edited.city"
              label="City"
              outlined
              dense
              counter
              :disabled="disableAll"
              maxlength="75"
              data-testid="company-city"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="edited.province"
              label="Province"
              outlined
              dense
              counter
              :disabled="disableAll"
              maxlength="30"
              data-testid="company-province"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="edited.postalCode"
              label="Postal Code"
              outlined
              dense
              counter
              maxlength="200"
              :disabled="disableAll"
              data-testid="company-postal-code"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="edited.phone"
              label="Phone"
              outlined
              dense
              counter
              maxlength="14"
              :disabled="disableAll"
              :rules="rules.phone"
              data-testid="company-phone"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="msaBlue white--text"
        :disabled="disableSave"
        @click="$emit('save')"
        data-testid="company-save"
      >
        save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'GeneralInformation',
  props: {
    companyDetail: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    disableSave: {
      type: Boolean,
    },
    disableAll: {
      type: Boolean,
    },
  },
  watch: {
    loading(val) {
      if (!val) {
        this.edited = {
          ...this.companyDetail,
        };
      }
    },
    edited: {
      handler() {
        this.$emit('update', this.updated);
      },
      deep: true,
    },
  },
  computed: {
    updated() {
      return {
        name: this.edited.name,
        description: this.edited.description,
        addressLine1: this.edited.addressLine1,
        addressLine2: this.edited.addressLine2,
        city: this.edited.city,
        province: this.edited.province,
        postalCode: this.edited.postalCode,
        phone: this.edited.phone,
      };
    },
  },
  data() {
    return {
      edited: {},
      rules: {
        name: [(v) => !!v || 'Name is required'],
        phone: [(v) => this.$helpers.validatePhone(v)],
      },
    };
  },
  mounted() {},
};
</script>

<style></style>

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12">
            <GeneralInformation
              :companyDetail="edited"
              :loading="loading"
              :disableSave="disableSave"
              :disableAll="false"
              @update="updateInfo($event)"
              @save="saveDetails()"
            />
          </v-col>
          <v-col cols="12">
            <CompanyLogo
              :hasLogo="edited.hasLogo"
              @toggleHasLogo="edited.hasLogo = !edited.hasLogo"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GeneralInformation from '@/components/CompanyDetail/GeneralInformation.vue';
import CompanyLogo from '@/components/CompanyDetail/CompanyLogo.vue';
export default {
  name: 'CompanyProfilePage',
  components: {
    GeneralInformation,
    CompanyLogo,
  },
  computed: {
    disableSave() {
      return (
        (this.companyProfile.addressLine1 == this.edited.addressLine1 &&
          this.companyProfile.addressLine2 == this.edited.addressLine2 &&
          this.companyProfile.city == this.edited.city &&
          this.companyProfile.province == this.edited.province &&
          this.companyProfile.postalCode == this.edited.postalCode &&
          this.companyProfile.phone == this.edited.phone) ||
        this.$helpers.validatePhone(this.edited.phone) !== true
      );
    },
  },
  data() {
    return {
      loading: true,
      edited: {},
      companyProfile: {},
    };
  },
  methods: {
    getCompanyProfile() {
      const params = {
        loaderText: 'loading...',
      };
      const url = 'get-company-profile?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.companyProfile = response.data;
          this.companyProfile.hasLogo = !!this.companyProfile.hasLogo;
          this.edited = { ...this.companyProfile };
          this.loading = false;
        })
        .catch((error) => error);
    },
    updateInfo(payload) {
      this.edited.addressLine1 = payload.addressLine1;
      this.edited.addressLine2 = payload.addressLine2;
      this.edited.city = payload.city;
      this.edited.province = payload.province;
      this.edited.postalCode = payload.postalCode;
      this.edited.phone = payload.phone;
    },
    saveDetails() {
      const params = {
        loaderText: 'loading...',
        profile: {
          addressLine1: this.edited.addressLine1,
          addressLine2: this.edited.addressLine2,
          city: this.edited.city,
          province: this.edited.province,
          postalCode: this.edited.postalCode,
          phone: this.edited.phone,
        },
      };
      const url = 'update-company-profile?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.getCompanyProfile();
        })
        .catch((error) => error);
    },
  },
  mounted() {
    this.getCompanyProfile();
  },
};
</script>

<style></style>
